<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" width="800px" persistent>
        <v-card>
          <!--<v-system-bar :color="`${lista.colorEstado} lighten-1`" height="10"></v-system-bar>-->
          <v-card-text class="pa-5">
            <v-row class="px-2 pt-3">
              <v-col cols="12" class="pa-0 pl-3 pt-2 mb-1 d-flex justify-center align-center">
                <v-icon class="mr-3">mdi-dock-top</v-icon>
                <span
                  v-if="!selectedNombre"
                  @click="selectNombre"
                  class="font-weight-black w-100"
                  style="font-size: 18px; cursor: text; color: black;"
                  >{{ item.nombre }}</span
                >
                <v-text-field
                  v-else
                  ref="nombre"
                  @blur="editNombre"
                  v-on:keyup.enter="editNombre"
                  outlined
                  dense
                  class="uppercase"
                  hide-details="auto"
                  v-model="item.nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0 pl-3">
                <div style="margin-left: 36px;">
                  <span class="text-caption">En la lista </span>
                  <span class="text-caption font-weight-black text-decoration-underline">{{ lista.nombre }}</span>
                </div>
              </v-col>
              <v-col cols="12" class="pa-0 pl-3">
                <div style="margin-left: 34px;">
                  <template v-for="(responsable, index3) in item.responsables">
                    <v-tooltip v-if="index3 < 2" bottom :key="`id-res-list-${index3}`">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          x-small
                          fab
                          dark
                          :style="`${index3 > 0 ? 'left: -' + 10 * index3 + 'px;' : ''}`"
                          @click="editarResponsables"
                          v-on="tooltip"
                          :color="`${lista.color} lighten-1`"
                          class="pa-0"
                        >
                          {{ iniciales(responsable.nombres) }}
                        </v-btn>
                      </template>
                      <span>{{ responsable.nombres }}</span>
                    </v-tooltip>
                  </template>
                  <v-tooltip v-if="item.responsables.length > 2" bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        x-small
                        fab
                        dark
                        :color="`${lista.color} lighten-3`"
                        @click="editarResponsables"
                        class="pa-0"
                        v-on="tooltip"
                        style="left: -20px;"
                      >
                        ...
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 px-2 py-0 pt-6">
              <v-col cols="12" md="9" class="pa-0 pl-3 pt-2 mb-3">
                <v-col cols="12" class="pa-0" style="display: flex; align-items: center;">
                  <v-icon class="mr-3">mdi-format-align-left</v-icon>
                  <span class="font-weight-black mr-2" style="font-size: 16px; color: black;">DESCRIPCION</span>
                  <v-btn
                    v-if="!selectedDescripcion && abreviatura != estados.REALIZADO"
                    @click="selectDescripcion"
                    depressed
                    small
                    >Editar</v-btn
                  >
                </v-col>
                <v-col cols="12" class="pa-0 mt-2">
                  <div style="margin-left: 36px;">
                    <div
                      v-if="!selectedDescripcion"
                      @click="selectDescripcion"
                      class="w-100"
                      style="font-size: 14px; cursor: text;"
                    >
                      <div v-if="descripcion != null">
                        <p class="mb-0" v-for="(descripcionPart, indexD) in descripcion.split('\n')" :key="indexD">
                          {{ descripcionPart }}
                        </p>
                      </div>
                    </div>
                    <div v-else @blur="deselectedDescripcion">
                      <v-textarea
                        class="mb-2"
                        hide-details="auto"
                        v-on:keyup.esc="deselectedDescripcion"
                        ref="descripcion"
                        outlined
                        dense
                        v-model="descripcion"
                        height="100"
                      ></v-textarea>
                      <v-btn color="primary" class="mr-2" small @click="editDescripcion">
                        Guardar
                      </v-btn>
                      <v-btn small icon @click="deselectedDescripcion"><v-icon>mdi-close</v-icon></v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="pa-0 mt-6" style="display: flex; align-items: center;">
                  <v-icon class="mr-3">mdi-priority-high</v-icon>
                  <span class="font-weight-black mr-2" style="font-size: 16px; color: black;">PRIORIDAD</span>
                  <div>
                    <v-menu
                      v-model="editingPrioridad"
                      :disabled="abreviatura != estados.REALIZADO ? false : true"
                      :close-on-content-click="true"
                      offset-y
                    >
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-btn plain :depressed="true" v-bind="attrs" v-on="{ ...menu }" class="pa-0">
                          <v-chip outlined class="mx-2" :color="prioridad(item.prioridad).color" small label>
                            <strong>{{ prioridad(item.prioridad).nombre }}</strong>
                          </v-chip>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item dense v-for="(prioridad, indexP) in prioridades" v-bind:key="indexP" link>
                          <v-list-item-title
                            v-text="prioridad.nombre"
                            @click="cambiarPrioridad(prioridad.nombre)"
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
              </v-col>
              <v-col cols="12" md="3">
                <span class="font-weight-bold">Añadir a la actividad</span>
                <v-btn class="my-2" block small @click="editarResponsables">
                  <v-icon small class="mr-2">mdi-account-outline</v-icon>Miembros
                </v-btn>
                <v-menu
                  v-model="editingFecha"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  transition="slide-x-transition"
                  offset-y
                  bottom
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="my-2" v-bind="attrs" v-on="on" block small>
                      <v-icon small class="mr-2">mdi-calendar-clock</v-icon>Fechas
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list dense>
                      <v-list-item>
                        <v-menu
                          ref="menuFI"
                          :disabled="item.referencia == 'KAN' && abreviatura != estados.REALIZADO ? false : true"
                          v-model="menuFI"
                          :close-on-content-click="false"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-form ref="formFechaInicio" v-model="validFormFechaInicio" class="w-100">
                              <v-text-field
                                v-model="item.fechaInicioAux"
                                readonly
                                label="Fecha Inicio"
                                :rules="[rules.fechaInicio(item.fechaInicioAux, item.fechaVencAux)]"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </v-form>
                          </template>
                          <v-date-picker
                            v-model="item.fechaInicioAux"
                            locale="es-es"
                            :first-day-of-week="1"
                            no-title
                            @input="editarFechaInicio"
                          ></v-date-picker>
                        </v-menu>
                      </v-list-item>
                      <v-list-item class="mb-2">
                        <v-form ref="formFechaVenc" v-model="validFormFechaVenc" class="w-100">
                          <v-menu
                            ref="menuFV"
                            :disabled="item.referencia == 'KAN' && abreviatura != estados.REALIZADO ? false : true"
                            v-model="menuFV"
                            :close-on-content-click="false"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.fechaVencAux"
                                readonly
                                label="Fecha Vencimiento"
                                :rules="[rules.fechaVenc(item.fechaVencAux, item.fechaInicioAux)]"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.fechaVencAux"
                              locale="es-es"
                              :first-day-of-week="1"
                              no-title
                              @input="editarFechaVencimiento"
                            ></v-date-picker>
                          </v-menu>
                        </v-form>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
                <v-menu v-model="editingArchivo" :close-on-content-click="false" :nudge-width="300" offset-y bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="my-2" block v-bind="attrs" v-on="on" small>
                      <v-icon small class="mr-2">mdi-file-outline</v-icon>Adjuntos
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list dense>
                      <v-list>
                        <v-list-item
                          v-if="item.nombrefichero != '' && item.nombrefichero != null"
                          link
                          @click="descargarArchivo"
                        >
                          <v-icon class="mr-2">mdi-download</v-icon>
                          <span>Descargar</span>
                        </v-list-item>
                        <v-list-item
                          v-if="item.nombrefichero != '' && item.nombrefichero != null && puedePrevisualizar(item)"
                          link
                          @click="previsualizarArchivo(item)"
                        >
                          <v-icon class="mr-2">mdi-file-eye</v-icon>
                          <span>Previsualizar</span>
                        </v-list-item>
                        <v-list-item
                          v-if="
                            item.nombrefichero != '' && item.nombrefichero != null && abreviatura != estados.REALIZADO
                          "
                          link
                          @click="eliminarArchivo"
                        >
                          <v-icon class="mr-2">mdi-delete</v-icon>
                          <span>Eliminar</span>
                        </v-list-item>
                        <v-divider
                          v-if="
                            abreviatura != estados.REALIZADO && item.nombrefichero != '' && item.nombrefichero != null
                          "
                        />
                        <v-list-item v-if="abreviatura != estados.REALIZADO" link>
                          <v-form :ref="`formArchivo`" v-model="validArchive" lazy-validation class="d-flex w-100">
                            <v-file-input
                              v-model="archivo"
                              show-size
                              label="Archivo"
                              clearable
                              id="files"
                              ref="files"
                              class="mr-2"
                              :rules="rulesArchive"
                              prepend-icon="mdi-paperclip"
                            ></v-file-input>
                          </v-form>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="primary"
                                class="white--text mr-1"
                                small
                                icon
                                v-on="on"
                                @click="subirArchivo"
                              >
                                <v-icon dark>mdi-send</v-icon>
                              </v-btn>
                            </template>
                            <span>CARGAR ARCHIVO</span>
                          </v-tooltip>
                        </v-list-item>
                      </v-list>
                    </v-list>
                  </v-card>
                </v-menu>
                <!--<v-btn class="my-2" block small> <v-icon small class="mr-2">mdi-calendar-clock</v-icon>Fechas </v-btn>-->
                <!--<v-btn class="my-2" block small> <v-icon small class="mr-2">mdi-file-outline</v-icon>Adjuntos </v-btn>-->
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
        <v-menu
          v-model="editingResponsable"
          :close-on-content-click="false"
          :close-on-click="closeOnContentResponsable"
          @mousedown="closeOnContentResponsable = true"
          :nudge-width="400"
          :max-width="400"
          :position-x="positionX"
          :position-y="positionY"
          offset-x
          top
        >
          <v-card>
            <div class="px-3 py-4">
              <v-chip
                :close="responsable.tiporesponsable != 1 && abreviatura != estados.REALIZADO"
                small
                class="pl-0 my-1 mr-1"
                v-for="(responsable, index3) in item.responsables"
                v-bind:key="index3"
                @click:close="eliminarResponsable(index3)"
              >
                <v-avatar :color="`${lista.color} lighten-1`" size="36" class="mr-1">
                  <span class="white--text">{{ iniciales(responsable.nombres) }}</span>
                </v-avatar>
                {{ responsable.nombres }}
              </v-chip>
            </div>
            <v-divider v-if="abreviatura != estados.REALIZADO"></v-divider>
            <div v-if="abreviatura != estados.REALIZADO" class="px-3 d-flex align-center">
              <v-autocomplete
                v-model="responsablesSeleccionados"
                :search-input.sync="searchResponsable"
                @change="searchResponsable = ''"
                full-width
                :items="listaInvitados()"
                class="mr-2 pt-0"
                chips
                label="Introduce un nombre..."
                item-text="text"
                item-value="value"
                return-object
                multiple
              >
              </v-autocomplete>
              <v-btn outlined color="primary" small @click="agregarResponsables()">
                <v-icon small>mdi-send</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </v-dialog>
      <PreviewDocument
        :dialog="dialogPreviewDocument"
        :item="itemPreview"
        @close="
          dialogPreviewDocument = false;
          itemPreview = null;
        "
      ></PreviewDocument>
    </div>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import DashboardService from '../services/DashboardService';
import moment from 'moment';
import { decryptAES } from '@/components4x/utils/utils4x';
import Swal from 'sweetalert2';
export default {
  name: 'OpexDetalleForm',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: null
    },
    abreviatura: {
      type: String,
      default: ''
    },
    listadoResponsables: {
      type: Array,
      default: new Array()
    },
    lista: {
      type: Object,
      default: null
    },
    estados: {
      type: Object,
      default: null
    },
    prioridades: {
      type: Array,
      default: new Array()
    },
    fechaInicioSemana: {
      type: String,
      default: null
    },
    fechaFinSemana: {
      type: String,
      default: null
    }
  },
  watch: {
    item() {
      this.descripcion = this.item.descripcion;
      this.item.nombre = this.item.nombre.toUpperCase();
    },
    editingFecha() {
      this.item.fechaInicioAux = this.item.fechaInicio;
      this.item.fechaVencAux = this.item.fechaVenc;
    }
  },
  data: () => ({
    height: 130,
    search: '',
    loading: false,
    dashboardService: null,
    showLoading: false,
    archive: null,
    validForm: true,
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      fechaInicio: (value, fechaVenc) => {
        if (value && fechaVenc)
          return value <= fechaVenc || 'Fecha inicio debe ser menor o igual a fecha de vencimiento';
        else return true;
      },
      fechaVenc: (value, fechaInicio) => {
        if (value && fechaInicio)
          return value >= fechaInicio || 'Fecha vencimiento debe ser mayor o igual a fecha de inicio';
        else return true;
      },
      intervaloSemanas(fechaVenc, inicioSemana, finSemana) {
        if (fechaVenc)
          return (
            (fechaVenc <= finSemana && fechaVenc >= inicioSemana) ||
            'Fecha vencimiento debe pertenecer a la semana actual'
          );
        else return true;
      }
    },
    expanded: [],
    listado: [],
    editingResponsable: null,
    editingFecha: null,
    editingArchivo: null,
    editingPrioridad: null,
    menuFI: false,
    menuFV: false,
    archivo: null,
    rulesArchive: [
      (value) => !!value || 'Campo requerido.',
      (value) => !value || value.size < 25000000 || 'El archivo debe de tener menos de 25 MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (
          res != 'exe' &&
          res != 'sql' &&
          res != 'jar' &&
          res != 'air' &&
          res != 'src' &&
          res != 'vb' &&
          res != 'bat' &&
          res != 'dll' &&
          res != 'com' &&
          res != 'bin' &&
          res != 'apk' &&
          res != 'app' &&
          res != 'cgi' &&
          res != 'wsf' &&
          res != 'gadget' &&
          res != 'log' &&
          res != 'css' &&
          res != 'sqlite' &&
          res != 'psd' &&
          res != 'ai' &&
          res != 'bak' &&
          res != 'js' &&
          res != 'cmd'
        )
          return true;
        return 'No se permite ese tipo de archivos.';
      }
    ],
    descripcion: '',
    /*item: {
      nombre: 'Actividad',
      descripcion: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
      when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
      It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
    },*/
    selectedNombre: false,
    selectedDescripcion: false,
    listadoEquipos: [],
    listadoCecos: [],
    listadoMonedas: [
      { value: 'D', text: 'Dólares' },
      { value: 'S', text: 'Soles' },
      { value: 'E', text: 'Euros' }
    ],
    responsablesSeleccionados: [],
    closeOnContentResponsable: true,
    positionX: 0,
    positionY: 0,
    searchResponsable: '',
    dialogPreviewDocument: false,
    itemPreview: null,
    validArchive: true,
    validFormFechaInicio: true,
    validFormFechaVenc: true
  }),
  computed: {},
  components: {
    PreviewDocument: () => import('./PreviewDocument')
  },
  methods: {
    prioridad(prioridad) {
      let prioridadAux = this.prioridades.find((el) => el.nombre == prioridad);
      if (!prioridadAux) prioridadAux = { color: 'grey', nombre: '...' };
      return prioridadAux;
    },
    listaInvitados() {
      let nuevosInvitados = [];
      this.listadoResponsables.forEach((el) => {
        let existe = 0;
        this.item.responsables.forEach((el2) => {
          if (el.idresponsable == el2.idresponsable) existe = 1;
        });
        if (existe == 0) nuevosInvitados.push(el);
      });
      return nuevosInvitados;
    },
    selectAllPeriodos() {
      this.$nextTick(() => {
        if (this.checkAllPeriodos) {
          this.item.periodo = [];
        } else {
          this.item.periodo = this.listadoPeriodos.map((element) => {
            return element.value;
          });
        }
      });
    },
    inicializarItem() {
      if (this.$refs.form) this.$refs.form.reset();
      this.item = {
        class: 'class-items-fixed-ind',
        item: '',
        idlocacionbudget: '',
        descripciondetallada: '',
        idservicio: '',
        servicio: {
          value: '',
          text: '',
          idservicio: '',
          Servicio: '',
          idcuentamayor: '',
          CuentaMayor: '',
          idcuentacontable: '',
          CuentaContable: ''
        },
        idtipoequipo: '',
        equipo: {
          value: '',
          text: '',
          equipo: '',
          contrato: '',
          gama: '',
          precio: ''
        },
        idequipo: '',
        gama: '',
        contrato: '',
        idcuentamayor: '',
        idcuentacontable: '',
        idsubarea: '',
        idceco: '',
        ceco: { value: '', text: '', idceco: '', ceco: '', textobreve: '', idtipogastobudget: '', tipogasto: '' },
        idtipogastobudget: '',
        periodo: '',
        idmoneda: 'D',
        precio: '',
        cantidad: '',
        monto: '',
        observaciones: '',
        accion: 0,
        activo: 1
      };
    },
    close() {
      //this.inicializarItem();
      this.$emit('close');
    },
    editarResponsables($event) {
      this.editingResponsable = !this.editingResponsable;
      this.responsablesSeleccionados = [];
      this.searchResponsable = '';
      this.positionX = $event.x + 50;
      this.positionY = $event.y;
    },
    async exportar(codigo) {
      this.loading = true;
      const token = localStorage.getItem('token');
      const empresa = decryptAES(localStorage.getItem('emp'));
      await this.matrizService.downloadResource(
        'descargarindicador',
        {
          idempresa: empresa,
          token: token,
          idhn: codigo
        },
        `HN-${codigo}-${moment().format('YYYYMMDDHHMM')}.pdf`
      );
      this.loading = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    seleccionEquipo() {
      this.item.precio = Number(this.item.equipo.precio).toFixed(2);
    },
    selectNombre() {
      if (this.abreviatura != this.estados.REALIZADO) {
        this.selectedNombre = true;
        this.$nextTick(() => this.$refs.nombre.focus());
      }
    },
    async editarDatos(objeto) {
      const res = await this.dashboardService.post().execResource('editarTarea', objeto);
      if (!res.status) {
        this.alertDialog('error', res.data);
        this.$emit('listarTareas');
      }
    },
    async cambiarPrioridad(prioridad) {
      this.item.prioridad = prioridad;
      await this.editarDatos({
        idtarea: this.item.idtarea,
        idempresa: this.item.idempresa,
        prioridad: this.item.prioridad
      });
    },
    async editNombre() {
      this.selectedNombre = false;
      this.item.nombre = this.item.nombre.toUpperCase();
      await this.editarDatos({ idtarea: this.item.idtarea, idempresa: this.item.idempresa, nombre: this.item.nombre });
    },
    selectDescripcion() {
      if (this.abreviatura != this.estados.REALIZADO) {
        this.selectedDescripcion = true;
        this.$nextTick(() => this.$refs.descripcion.focus());
      }
    },
    deselectedDescripcion() {
      this.selectedDescripcion = false;
      this.descripcion = this.item.descripcion;
    },
    async editDescripcion() {
      this.selectedDescripcion = false;
      this.item.descripcion = this.descripcion;
      await this.editarDatos({
        idtarea: this.item.idtarea,
        idempresa: this.item.idempresa,
        descripcion: this.item.descripcion
      });
    },
    async editarInvitados(actividad, responsables, accion) {
      responsables = responsables.map((el) => {
        return { ...el, accion: accion };
      });
      const res = await this.dashboardService.post().execResource('agregarInvitados', {
        idempresa: actividad.idempresa,
        idtarea: actividad.idtarea,
        invitados: responsables
      });
      if (!res.status) this.alertDialog('error', res.data);
      this.responsablesSeleccionados = [];
    },
    async eliminarResponsable(index3) {
      this.closeOnContentResponsable = false;
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de eliminar el responsable?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        let responsable = this.item.responsables[index3];
        this.item.responsables.splice(index3, 1);
        await this.editarInvitados(this.item, [responsable], 'E');
      }
      this.closeOnContentResponsable = true;
    },
    async agregarResponsables() {
      this.closeOnContentResponsable = false;
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de agregar los responsables?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      this.closeOnContentResponsable = true;
      if (confirmed.isConfirmed) {
        this.responsablesSeleccionados.forEach((el) => {
          this.item.responsables.push(el);
        });
        await this.editarInvitados(this.item, this.responsablesSeleccionados, 'N');
        this.responsablesSeleccionados = [];
      }
    },
    iniciales(nombre) {
      let parts = nombre !== undefined ? nombre.split(' ') : [];
      let iniciales = '';
      parts.forEach((el, index) => {
        if (index < 2) iniciales += el[0];
      });
      return iniciales;
    },
    async descargarArchivo() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de descargar el archivo?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        const token = localStorage.getItem('token');
        await this.dashboardService.downloadResource(
          'descargarFichero',
          {
            idtarea: this.item.idtarea,
            token: token
          },
          typeof this.item.nombrefichero == 'object' ? this.item.nombrefichero.name : this.item.nombrefichero
        );
      }
    },
    async subirArchivo() {
      if (this.$refs.formArchivo.validate()) {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: '¿Está seguro de subir el archivo?',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        });
        if (confirmed.isConfirmed) {
          this.showLoading = true;
          this.item.nombrefichero = this.archivo.name;
          const formData = new FormData();
          formData.append('idtarea', this.item.idtarea);
          formData.append('idempresa', this.item.idempresa);
          formData.append('fichero', this.archivo);
          formData.append('editarfichero', 1);
          this.editarDatos(formData);
          await new Promise(() =>
            setTimeout(() => {
              this.showLoading = false;
              this.archivo = null;
            }, 3000)
          );
        }
        this.archivo = null;
      }
    },
    async eliminarArchivo() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de eliminar el archivo?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.item.nombrefichero = '';
        const formData = new FormData();
        formData.append('idtarea', this.item.idtarea);
        formData.append('idempresa', this.item.idempresa);
        formData.append('fichero', '');
        formData.append('editarfichero', 1);
        this.editarDatos(formData);
      }
    },
    async editarFechaInicio($event) {
      this.menuFI = false;
      this.item.fechaInicioAux = $event;
      await this.$nextTick(() => {
        if (this.$refs.formFechaInicio.validate()) {
          this.item.fechaInicio = $event;
          this.editarDatos({
            idtarea: this.item.idtarea,
            idempresa: this.item.idempresa,
            fechainicio: this.item.fechaInicio
          });
        }
      });
    },
    async editarFechaVencimiento($event) {
      this.menuFV = false;
      this.item.fechaVencAux = $event;
      await this.$nextTick(() => {
        if (this.$refs.formFechaVenc.validate()) {
          this.item.fechaVenc = $event;
          this.editarDatos({
            idtarea: this.item.idtarea,
            idempresa: this.item.idempresa,
            fechavencimiento: this.item.fechaVenc
          });
        }
      });
    },
    previsualizarArchivo(actividad) {
      let url = process.env.VUE_APP_BACK_URL + 'storage/kanban/' + actividad.idtarea;
      let partes = actividad.nombrefichero.split('.');
      let extension = partes[partes.length - 1];
      this.itemPreview = { extension: extension, previewURL: url };
      this.dialogPreviewDocument = true;
    },
    puedePrevisualizar(actividad) {
      let partes = actividad.nombrefichero.split('.');
      let extension = partes[partes.length - 1];
      if (
        extension === 'doc' ||
        extension === 'docx' ||
        extension === 'ppt' ||
        extension === 'pptx' ||
        extension === 'xls' ||
        extension === 'xlsx' ||
        extension === 'pdf' ||
        extension === 'jpg' ||
        extension === 'jpeg' ||
        extension === 'png'
      ) {
        return true;
      }
      return false;
    }
  },
  async created() {
    this.dashboardService = this.$httpService(new DashboardService(), this);
    //this.inicializarItem();
  } /*,
  async created() {
    this.dashboadService = this.$httpService(new DashboardService(), this);
    this.inicializarItem();
  }*/
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.uppercase input {
  text-transform: uppercase;
}
</style>
